<template>
    <div class="blank">
        <div class="box">
            <img src="@/assets/yq/index-empty.png" alt="">
            <div class="text">{{title}}</div>
            <div class="tips">
                <div v-if="tip=='ht'">感谢您选择建管家，如有疑问请联系业务员或拨打客服电话
                    <a :href="'tel:' + telePhone">{{telePhone}}</a>
                </div>
                <div v-else-if="tip=='zs'">感谢您选择建管家，如有证书请联系业务员或拨打
                    <a :href="'tel:' + telePhone">{{telePhone}}</a>
                </div>
                <div v-else-if="tip=='xx'">感谢您选择建管家</div>
                <div v-else-if="tip=='ts'">感谢您选择建管家</div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/config/config'
    export default {
        props: {
        	title: {
        		type: String,
        		default: ''
        	},
			tip: {
        		type: String,
        		default: ''
        	}
        },
        data() {
            return {
                telePhone: ""
            }
        },
        created(){
            this.telePhone = config.telePhone
        }
    }
</script>

<style scoped>
    .box {
        position: absolute;
        /* width: 100%; */
        /* height: 100%; */
        top: 0;
        left: 0;
        bottom: 0;
		right: 0;
		margin: auto;
		text-align: center;
        z-index: -1;
    }
    .box img {
        width: 150px;
        height: 79px;
        margin-top: 240px;
    }
    .box .text {
        color: #222222;
        font-size: 16px;
		font-weight: 500;
    }
    .box .tips {
		width: 225px;
        color: #999999;
        font-size: 14px;
		margin: 20px auto;
    }
	.box .tips a{
		margin-left: 5px;
		color: #2D74FE;
	}
</style>
