<template>
    <div class="message-list" >
		<div class="main">
			<div v-if="messageList.length">
                <van-list
                    v-model="loading"
                    :finished="isDow"
                    finished-text="暂无更多"
                    @load="onReachBottom"
                    :offset="10"
                >
                    <message-item :type="type"
                        :detailObj="item"
                        :idx="index"
                        v-for="(item,index) in messageList"
                        :key="index"
                        @hField="modifyItemField"
                    />
                    <!-- <div class="lean-more color-999" v-if="isDow">加载更多数据~</div>
                    <div class="no-more color-999" v-else>暂无更多~</div> -->
                </van-list>
			</div>
			<div v-if="isEmpty">
				<blank title="暂无更多消息~" tip="xx" />
			</div>
		</div>
	</div>
</template>

<script>
import messageItem from "@/components/message/messageItem.vue";
import blank from "@/components/blank.vue";
import {getNoticeSystemPage} from "@/api/message";
import { getContractById, getContractFile } from "@/api/contract";
export default {
	components: {
		messageItem,
		blank
	},
	data() {
		return {
			title:"",
			type:"",
			messageList:[],
			page:1,
			size:5,
			totalPage:"",
			isDow:false,
            isEmpty:false,
            loading: false,
            testObj:{
                createTime:"2021-04-23 17:29:32",
                customerId:"545033207378235392",
                describes:"这是测试数据，还有三天到期，请及时处理！如已处理，请忽略此消息",
                id: "545505146382475264",
                isSee: 0,
                resourcesId: "545122315855368192",
                seeTime: null,
                title: "这是测试数据签约醒",
                type: 9,
                updateTime: null,
            }
		}
	},
	methods: {
		requestMessage(param) {
            this.isEmpty = false;
            this.loading = true;
			getNoticeSystemPage(param).then(res=>{
				let {status} = res;
				if(status){
					let {records,pages} = res.data.noticeSystemPage;
					let old  = this.messageList;
					if(this.page == 1){
						this.totalPage = pages;
                        if(records.length == 0){
                            this.isEmpty = true;
                            return;
                        }
					}else if(this.page >= pages){
						this.isDow = true;
					}
                    this.loading = false;
					this.messageList = [...old,...records];
				}
			})
		},
		modifyItemField({idx,val}){
			let obj = JSON.parse(JSON.stringify(this.messageList[idx]));
			obj.isSee = val;
			this.$set(this.messageList,idx,obj);
		},
        onReachBottom(){
            console.log('onReachBottom',this.page);
            console.log('totalPage',this.totalPage);          
            if(this.page <= this.totalPage){
                let param = {
                    current: ++this.page,
                    module: this.type,
                    size: this.size,
                }
                this.requestMessage(param);
            }
        },
	},

	created(){
		let {type,title} = this.$route.query;
		// var title = "系统提醒";
		// var type = "systemModule";

		this.title = title;
		this.type = type;
		console.log(title,type);
		console.log(this.page);
		this.messageList = [];
		this.page = 1;
		let param = {
			module:type,
			current:this.page,
			size:this.size,
		}
		this.requestMessage(param);
       
	}
};
</script>

<style scoped>
div{
	box-sizing: border-box;
}
.message-list{
	padding-top: 50px;
}
.message-list .main{
	padding-top: 39px;
	padding-bottom: 20px;
}
.message-list .lean-more,.no-more{
	height: 15px;
	line-height: 15px;
	font-size: 14px;
	text-align: center;
}

</style>
