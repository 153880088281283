import request,{ httpPost } from "@/utils/fetch";

// 获取首页合同列表
export const getContractList = (params) => {
    return httpPost('/java-signplatform-cloud/contract/getContractList',params)
}
// 获取首页合同详情
export const getContractById = (params) => {
    return request({
		method: 'post',
		url: '/java-signplatform-cloud/contract/getContractInfo',
		data: params,
        // headers: {
        //     'Content-Type': "text/plain"
        // }
	})
}
// 获取各种状态合同数量
export const getContractEveryNum = (params) => {
    return httpPost('/java-signplatform-cloud/contract/getContractStaysCount',params)
}
// 获取各种状态合同数量
export const getContractFile = (params) => {
    return httpPost('/contract/getContractFile',params)
}
// 获取合同状态
export const getContractStatus = (params) => {
    return httpPost('/contract/getContractStatus',params)
}
