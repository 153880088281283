import {httpPost} from "@/utils/fetch";

// 获取消息未读统计情况
export const getNoticeMerge = () => {
    return httpPost('/cNoticeSystem/getNoticeMerge')
}
// 获取消息未读统计情况
export const getNoticeSystemPage = (params) => {
    return httpPost('/cNoticeSystem/getNoticeSystemPage',params)
}
// 消息详情+消息查看状态修改
export const seeNoticeSystem = (params) => {
    return httpPost('/cNoticeSystem/seeNoticeSystem',params)
}