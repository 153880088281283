<template>
	<div class="message-item"
		@click="messageDetail"
	>
		<div class="time">
			{{detailObj.createTime}}
		</div>
		<div class="content bg-fff">
			<div class="title"
				:style="{color:(detailObj.isSee?'#ccc':'#222')}"
			>
				{{detailObj.title}}
			</div>
			<div class="describe"
				:style="{color:(detailObj.isSee?'#ccc':'#999')}"
			>
				{{detailObj.describes}}
			</div>
			<!-- <div class="detail"
				:style="{color:(detailObj.isSee?'#ccc':'#3D7EFF')}"
				v-if="type!='contractModule'"
			>
				查看详情
			</div> -->
			<div v-if="isFirstLoad">
				<div class="detail"
					:style="{color:(detailObj.isSee?'#ccc':'#3D7EFF')}"
					v-if="isJump"
				>
					查看详情
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import {seeNoticeSystem} from "@/api/message";
import { getContractById, getContractFile ,getContractStatus } from "@/api/contract";
	export default {
		props: {
			type: {
				type: String,
				default: ""
			},
			detailObj: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			idx: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				messageEnum:[
					{type:1,typeInfo: "系统消息",isHasJump:true,jumpUrl:"/officialMessage"},
					{type:2,typeInfo: "意见回馈-发起",isHasJump:false,jumpUrl:""},
					// {type:5,typeInfo: "投诉-回调",isHasJump:true,jumpUrl:"/complaintDetail"},
					{type:5,typeInfo: "投诉-回调",isHasJump:false,jumpUrl:""},
					{type:6,typeInfo: "待签约提醒", isHasJump:true,jumpUrl:"/pages/index/outH5/main"},
					{type:7,typeInfo: "签约成功提醒", isHasJump:true,jumpUrl:"/detail"},
					{type:8,typeInfo: "签约逾期提醒", isHasJump: true,jumpUrl:"/index"},
					{type:9,typeInfo: "签约到期提醒", isHasJump: true,jumpUrl:"/index"},
					{type:10,typeInfo: "合同专属顾问已变更", isHasJump: false,jumpUrl:""},
					{type:11,typeInfo: "合同到期提醒", isHasJump: false,jumpUrl:""},
					{type:12,typeInfo: "云签合同作废提醒", isHasJump: false,jumpUrl:""},
				],
				isJump:"",
				isFirstLoad:false,
			}
		},
		methods: {
			messageDetail() {

				let {id,type,resourcesId} = this.detailObj;
				console.log('detailObj',this.detailObj);
				if(type == 1){
					let {url,isHasJump} = this.handleUrl(type,id);
					console.log('offmessagfe,isHasJump',url,isHasJump);
					if(isHasJump){
						// wx.navigateTo({url});
						this.$router.push({
							path:"/officialMessage",
							query:{
								id
							}
						})
						return;
					}
				}else{
					seeNoticeSystem({id}).then(res=>{
						let {status} = res;
						if(status){
							let resId = res.data.resourcesId;
							// let resId = "545122315855368192";
							let {url,isHasJump} = this.handleUrl(type,resId);
							console.log('url,isHasJump',url,isHasJump);
							if(isHasJump){
								// wx.navigateTo({url});
								this.$router.push({
									path:url,
									id:{
										id
									}
								})
							}
							this.handleField(this.idx,1);

						}
					})
				}

			},
			handleUrl(type,id){
				let url;
				let isHasJump;
				this.messageEnum.forEach(val=>{
					if(val.type == type){

            		if(type == 6 || type == 7 || type == 8 || type == 9){
						getContractStatus({id}).then(res=>{
							let {status,data} = res;
							// let data = {
							//     contract:{
							//         status:3
							//     }
							// }
							if(data.status == 0){
								getContractFile({ id }).then((res) => {
									let { status,statusMessage } = res;
									if (status) {
										// let url = res.data;
										isHasJump = val.isHasJump;
										this.handleField(this.idx,1);
										if(res.data){
											window.location.href = res.data;
										}
										// wx.navigateTo({url});
										return;
									}else{
										this.$toast({
											message: statusMessage
										});
									}
								})
							}else if(data.status == 1){
								this.handleField(this.idx,1);
								// wx.navigateTo({
								//     url:"/pages/index/detail/main?id="+id
								// });
								this.$router.push({
									path:"/detail",
									query:{
										id
									}
								})
							}else if(data.status == 2 || data.status == 3 ){
								this.handleField(this.idx,1);
								let title = data.status == 2? "该合同签约已逾期不能签约。":"该合同签约已撤销不能签约。"
								this.$toast({
									message: title
								})
								return;
							}
						})
					}else{
						url = val.isHasJump? val.jumpUrl+"?id="+id : "";
						isHasJump = val.isHasJump;
					}
					}
				})
				return {url,isHasJump};
			},
			handleField(idx,val){
				this.$emit("hField",{idx,val});
			}
		},
		created(){
			let jump;
			this.messageEnum.forEach(val=>{
				if(val.type == this.detailObj.type){
					jump = val.isHasJump;
				}
			})
			this.isJump = jump;
			this.isFirstLoad = true;
		}
	}
</script>

<style scoped>
.message-item{
	width: 335px;
	margin: 0 auto;
	margin-bottom: 32px;
}
.message-item .time{
	text-align: center;
	font-size: 13px;
	color: #999;
}
.is-see{
	color: #ccc;
}
.message-item .content{
	padding: 20px 15px 0 15px;
	margin-top: 10px;

}
.message-item:last-of-type{
	margin-bottom: 20px;
}
.message-item .content .title{
	font-size: 17px;
	font-weight: 400;
}
.message-item .content .describe{
	margin-top: 12px;
	width: 295.5px;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding-bottom: 15px;
	color: #999;
}
.message-item .content .detail{
	border-top: 1px solid #EDF1F4;
	text-align: center;
	height: 45px;
	line-height: 45px;
	font-size: 14px;
	font-weight: 400;
	color: #3D7EFF;
}

</style>
